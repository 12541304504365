import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InformationCard(props) {
  return (
    <div className="effort-cards">
      <img className="effort-card-image" src={props.image} alt="Card" />
      <span className="effort-card-icon">
        <FontAwesomeIcon className="effort-fa-icon" icon={props.icon} />
      </span>
      <p className="effort-card-title">{props.title}</p>
      <p className="effort-card-description">{props.description}</p>
    </div>
  );
}

export default InformationCard;
