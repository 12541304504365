import React from "react";
import ContactPic from "../Assets/contact.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Contact.css";

function Contact() {

  return (
    <div className="contact-section" id="contact">
      <div className="contact-image-content">
        <img src={ContactPic} alt="Doctor Group" className="contact-image1" />
      </div>

      <div className="contact-text-content">
        <h3 className="contact-title">
          <span>Contact Us</span>
        </h3><br />

        <p className="contact-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#235B5B" }} /> <a className="contact-link" href='mailto:harita@dhiya-foundation.org'>Email</a><br /><br />
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#235B5B" }} /> <a className="contact-link" href='tel:'>Phone number</a><br /><br />
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#235B5B" }} /> <a className="contact-link" href='/privacy_policy'>Consent to receive SMS/EMAIL</a><br /><br />
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#235B5B" }} /> <a className="contact-link" href='#contact'>Log in using Apple ID</a><br /><br />
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#235B5B" }} /> <a className="contact-link" href='#contact'>Log in using Facebook ID, Instagram</a><br /><br />
        </p>
      </div>
    </div>
  );
}

export default Contact;
