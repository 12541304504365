import React, { useEffect, useState } from "react";
import HomePic from "../Assets/home.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Hero.css";

function Hero() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 500) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container" id="hero">
      <div className="hero-section">
        <div className="hero-image-section">
          <img className="hero-image1" src={HomePic} alt="Home" />
        </div>
        <div className="text-section">
          <h2 className="text-title">
            Dhiya Foundation
          </h2>
          <p className="text-descritpion">
            Revolutionizing Healthcare Through Community
            With Technology and Empathy
          </p>
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
