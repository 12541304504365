import React from "react";
import Doctor from "../Assets/service.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Service.css";

function Service() {

  return (
    <div className="ba-section" id="service">
      <div className="ba-image-content">
        <img src={Doctor} alt="Doctor Group" className="ba-image1"/>
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Seeking Services</span>
        </h3>
        <p className="ba-description">
          Our journey is on the path to reducing healthcare disparities in a variety of settings worldwide. Should your organization express interest in any of our initiatives, we invite you to complete the form below. We eagerly anticipate the opportunity to collaborate with you!
        </p>

        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#235B5B" }} /> Link to seeking services form: &nbsp;
          <a href="https://forms.office.com/r/as42Z10SPP">forms.office.com</a>
        </p>
      </div>
    </div>
  );
}

export default Service;
