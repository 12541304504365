import React from "react";
import Mission from "../Assets/mission.jpeg";
import SolutionStep from "./Step";
import "../Styles/Mission.css";

function About() {
    return (
        <>
            <div className="mission-section" id="mission">
                <div className="mission-image-content">
                    <img src={Mission} alt="Purpose" className="mission-image1" />
                </div>

                <div className="mission-text-content">
                    <h3 className="mission-title">
                        <span>Join the Mission</span>
                    </h3>

                    <SolutionStep
                        title="Meals & Moments Volunteers"
                        description="
                    Responsibilities: Deliver meals to aging adults and engage in meaningful conversations with those with whom you interact. This is a biweekly commitment.
                    Skills: Empathy, patience, communication, reliability"
                    />

                    <SolutionStep
                        title="Content Creators for Classes"
                        description="Responsibilities: Craft curricula to inform the rural aging population of certain health risks, skills for at-home healthcare, and preventative measures.
                    Skills: EMT-level medical certification and medical knowledge, research and data analysis, creative thinking, PowerPoint skills
                    "
                    />

                    <SolutionStep
                        title="Class Facilitators"
                        description="Responsibilities: Working with content creators, lead discussions based on the curricula to empower individuals and inform them of healthcare risks.
                    Skills: Public speaking, empathy, creative thinking, leadership"
                    />

                    <SolutionStep
                        title="Legal and Compliance Guidance"
                        description="Responsibilities: Provide legal guidance on do’s and don’ts.
                    Skills: Knowledge on non-profit law"
                    />

                    <SolutionStep
                        title="Fundraising Experts"
                        description="Responsibilities: Help secure funding and donations to support our mission’s sustainability and growth.
                    Skills: Grant writing, fundraising strategy, and donor relations"
                    />

                    <SolutionStep
                        title="Marketing and Outreach Champions"
                        description="Responsibilities: Spread awareness of our mission through digital marketing, social media campaigns, and outreach efforts.
                    Skills: Marketing strategy, social media management, content promotion, and branding"
                    />
                    <p className="mission-description">While these are the current volunteer positions available, we anticipate further expansion and the establishment of new organizational chapters in the second fiscal quarter of 2024. Consequently, additional positions will become available in line with our growth.</p>
                    <p className="mission-description">Link for interest forms: <a href="https://forms.office.com/r/jTdPZYT7dN">forms.office.com</a></p>
                </div>
            </div>
        </>
    );
}

export default About;
