import React from "react";
import DonatePic from "../Assets/donate.jpeg";
import Stripe from "../Assets/Stripe.png";
import "../Styles/Donate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";

function Donate() {

    const handleStripe = () => {
        const a = document.createElement('a')
        a.href = 'https://donate.stripe.com/8wM4jPawd86ydCEdQQ'
        a.target = '_blank'
        a.click()
        a.remove()
    };

    return (
        <div className="donate-container" id="donate">
            <div>
                <div className="donate-section">
                    <div className="donate-image-content">
                        <img src={DonatePic} alt="Purpose" className="donate-image1" />
                    </div>

                    <div className="donate-text-content">
                        <h3 className="donate-title">
                            <span>Donate</span>
                        </h3>
                        <p className="donate-contribute">
                            Dhiya Foundation is exempt from federal income tax under Internal Revenue Code (IRC) Section 501 (c)(3). Donors can deduct contributions under Section 170.<br />
                            <img src={Stripe} alt="Purpose" className="donate-stripe" />
                            <button
                                className="text-appointment-btn"
                                type="button"
                                onClick={handleStripe}
                            >
                                <FontAwesomeIcon icon={faMoneyCheckDollar} /> Donate
                            </button>
                            <br />
                        </p>
                        <p className="donate-description">
                            Dear Supporters,<br /><br />At Dhiya Foundation, we firmly believe that the journey towards healthier lifestyles begins by impacting one individual at a time, and one community at a time. I am thrilled to share this purpose with you—empowering individuals and fostering the growth of vibrant communities.<br /><br />In our commitment to distributing healthcare resources across communities, we are devoted to prudently managing every dollar you invest in us. Your contributions are meticulously directed toward initiatives that align with our mission. Whether it's funding Meals & Moments or supporting more extensive projects, such as assisting aging adults in affording safety measures for their homes, we approach every task with unwavering commitment to integrity and transparency.
                        </p>
                    </div>

                </div>
                <p className="donate-description2">
                    Your support is instrumental in catalyzing positive change and making our mission a reality. Your investment empowers individuals to pursue a healthier future, whether through our existing programs that encourage healthy aging-in-place or through the new initiatives we aspire to implement in diverse communities. We are deeply honored to have you as valued supporters in this journey.<br />Together, we can shape a world where individuals are confident in the healthcare support within their community, supported by us and each other. Thank you for considering our request to support our purpose.<br />For any questions or additional information about how donations are utilized, please feel free to reach us at <a href='mailto:AskAQuestion@dhiyafoundation.org'>AskAQuestion@dhiyafoundation.org</a><br /><br />
                    With gratitude,<br /><br />
                    Vaishnavi Katragadda
                </p>
            </div>
        </div>
    );
}

export default Donate;
