import React from "react";
import "../Styles/Info.css";
import Vision from "../Assets/vision.jpeg";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <div className="vision-container">
          <img className="vision-image" src={Vision} alt="Vision" />
          <div className="vision-text">
            <h3 className="info-title">
              <span>Our Vision</span>
            </h3>

            <p className="info-description">
              We envision a world where quality healthcare is universally accessible, transcending geographical, demographic, and social barriers. To accomplish these goals, we’ve started several initiatives supporting senior centers
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
