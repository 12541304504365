import React from "react";
import InformationCard from "./InformationCard";
import { faHeartPulse, faTruckMedical, faTooth } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Effort.css";
import Effort1 from "../Assets/effort1.png";
import Effort2 from "../Assets/effort2.png";
import Effort3 from "../Assets/effort3.png";

function Effort() {
  return (
    <div className="effort-container" id="services">
      <div className="effort-section">
        <h3 className="effort-title">
          <span>Current Effort</span>
        </h3>
        <div className="effort-cards-content">
          <InformationCard
            title="Collaborating Organizations"
            description="So far, we work with the Upper Valley Senior Center in Lebanon, NH and Bugbee Senior Center in White River Junction, VT. We teach classes biweekly, covering topics ranging from the long-term effects of loneliness to teaching fall safety and prevention or maintaining a healthy blood pressure. Starting in 2024, we’ll begin our Meals & Moments program at both centers."
            icon={faTruckMedical}
            image={Effort1}
          />

          <InformationCard
            title="Healthcare Discussions & First-Aid Classes"
            description="Together with local fire departments and the knowledge of our EMT-volunteers, we’ve developed curricula to empower our aging adult community. We cover information designed to inform individuals on preventative health measures to help them age-in-place, maintaining their present community connections and building more."
            icon={faHeartPulse}
            image={Effort2}
          />

          <InformationCard
            title="Meals & Moments"
            description="By collaborating with Meals on Wheels and our senior center partners, we deliver meals and conversations to individuals who may be isolated due to a variety of reasons. We forge meaningful connections with those involved, making frequent home-visits, writing letters, or through video calls."
            icon={faTooth}
            image={Effort3}
          />
        </div>
      </div>
    </div>
  );
}

export default Effort;
