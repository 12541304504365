import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";

function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
        <Link to="/">
          Dhiya Foundation
        </Link>
      </h1>

      <div className="legal-text-content">

        <p className="legal-title" id="terms">Terms of Service</p>
        <p className="legal-description1">
          <strong>1. Acceptance of Terms:</strong><br /> Using our services signifies your agreement with these terms.<br />
          <strong>2. Service Use:</strong><br /> For personal, non-commercial use. Comply with laws and respect others.<br />
          <strong>3. Intellectual Property:</strong><br /> All content is the Foundation's property. No unauthorized use.<br />
          <strong>4. Financial Advice Disclaimer:</strong><br /> Our information is educational, not professional financial advice. Consult a certified financial advisor for professional guidance.<br />
          <strong>5. Operational Capacity:</strong><br /> As a non-profit, we handle inquiries on a "best effort basis" and are not liable for delays or unaddressed issues.<br />
          <strong>6. Liability Limitation:</strong><br /> Protected against frivolous legal claims and not liable for service-related damages.<br />
          <strong>7. Service Modifications:</strong><br /> We may change or discontinue services without notice.<br />
          <strong>8. Privacy Policy:</strong><br /> Governed by our Privacy Policy.<br />
          <strong>9. Governing Law:</strong><br /> Subject to jurisdictional laws.<br />
          <strong>10. Contact:</strong><br /> For any questions, email <a href='mailto:webadmin@dhiya-foundation.org'>webadmin@dhiya-foundation.org</a>.<br />
        </p>

        <p className="legal-title" id="policy">Privacy Policy</p>
        <div className="legal-desc-container">
          <p className="legal-description">
            <strong>1. Introduction</strong><br />$Literacy Foundation ("we", "us") is committed to protecting your privacy. This Policy explains our practices regarding the collection, use, and protection of your personal information.<br />
            <strong>2. Information Collection</strong><br />We may collect personal and non-personal information such as:
            Contact details (name, address, email)
            Donation payment information
            Website usage data
            Communication preferences<br />
            <strong>3. Use of Information</strong><br />We may use your information to:
            Process donations and provide receipts
            Send updates and communications
            Improve our services
            Comply with legal requirements<br />
            <strong>4. Sharing of Information</strong><br />We do not sell or trade your personal data. It may be shared with trusted partners solely for operational purposes like payment processing.<br />
            <strong>5. Third-Party Websites and Services</strong><br />We may engage third-party services for various functions. While we prioritize your data's protection, we are not responsible for the privacy practices or data protection strategies of these third-party websites or services.<br />
            <strong>6. Security</strong><br />We employ measures to safeguard your information, but please note that no transmission method is completely secure.
          </p>
          <p className="legal-description">
            <strong>7. Your Rights</strong><br />You have the opportunity to:
            Request access to, modification of, or deletion of your personal data
            Opt-out of specific communications
            Inquire about data transfer or request data deletion
            Express concerns or submit inquiries<br />
            <strong>8. Cookies and Tracking</strong><br />We use cookies to improve your website experience. Cookie preferences can be managed in your browser.<br />
            <strong>9. Third-Party Links</strong><br />Our website includes links to external sites, whose privacy practices are not covered by this Policy.<br />
            <strong>10. Children's Privacy</strong><br />We do not knowingly collect data from under-13s. Contact webadmin@literacy-foundation.org to address concerns.<br />
            <strong>11. Changes to Privacy Policy</strong><br />We may modify this Policy, with the latest version available on our website.<br />
            <strong>12. Contact Us</strong><br />For privacy-related inquiries, contact webadmin@dhiya-foundation.org.
            By using our services, you consent to this Privacy Policy.<br />
          </p>
        </div>

        <p className="legal-title" id="sms">Email, SMS, Phone – Terms of service</p>
        <p className="legal-description1">
          <strong>1. Consent for Communications:</strong><br /> By volunteering, you consent to receive emails, SMS, and phone calls from us about volunteer opportunities and updates.<br />
          <strong>2. Nature of Communications:</strong><br /> Expect updates, reminders, and information relevant to your volunteer role.<br />
          <strong>3. Opt-Out Option:</strong><br /> You may opt out of communications at any time as per the instructions provided in our messages.<br />
          <strong>4. Data Privacy:</strong><br /> Your contact details are used solely for volunteer communications, adhering to our Privacy Policy.<br />
          <strong>5. Acceptance of Other Policies:</strong><br /> By using this service, you also agree to our Privacy Policy and Terms of Service.<br />
          <strong>6. Changes to Terms:</strong><br /> We may modify these terms, with notices of significant changes.<br />
          <strong>7. Contact Us:</strong><br /> For questions or preference updates, email webadmin@literacy-foundation.org.
          Your acceptance indicates agreement to these terms, our Privacy Policy, and Terms of Service, ensuring a well-informed and compliant volunteering experience.

        </p>
      </div>

      <div className="legal-footer">
        <p>Copyright © 2023 Dhiya  Foundation. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default LegalDocs;
