import React from "react";
import "../Styles/Roles.css";

function Roles() {

  return (
    <div className="role-container">
      <div className="mandatory">
        <div className="card">
          <div>
            <h3 className="header">Meals & Moments Volunteers</h3>
            <ul className="description">
              <li>
                Responsibilities: Working with content creators, lead discussions based on the curricula to empower individuals and inform them of healthcare risks.
              </li>
              <li>
                Skills: Empathy, patience, communication, reliability
              </li>
              <li>
                Commitment Required: Once in two weeks
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div>
            <h3 className="header">Content Creators for Classes</h3>
            <ul className="description">
              <li>Responsibilities: Working with content creators, lead discussions based on the curricula to empower individuals and inform them of healthcare risks.</li>
              <li>Skills: Public speaking, empathy, creative thinking, leadership</li>
              <li>Commitment Required: Once a week as required</li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div>
            <h3 className="header">Legal and Compliance Guidance</h3>
            <ul className="description">
              <li>Responsibilities: Provide legal guidance on do’s and don’ts.
              </li>
              <li>Skills: Knowledge on non-profit law</li>
              <li>Commitment Required: Once a month as required</li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div>
            <h3 className="header">Class Facilitators</h3>
            <ul className="description">
              <li>Responsibilities: Working with content creators, lead discussions based on the curricula to empower individuals and inform them of healthcare risks.</li>
              <li>Skills: Public speaking, empathy, creative thinking, leadership</li>
              <li>Commitment Required: Once a week as required</li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div>
            <h3 className="header">Fundraising Experts</h3>
            <ul className="description">
              <li>Responsibilities: Help secure funding and donations to support our mission’s sustainability and growth.</li>
              <li>Skills: Grant writing, fundraising strategy, and donor relations</li>
              <li>Commitment Required: Once a Quarter</li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div>
            <h3 className="header">Marketing and Outreach Champions</h3>
            <ul className="description">
              <li>Responsibilities: Spread awareness of our mission through digital marketing, social media campaigns, and outreach efforts</li>
              <li>Skills: Marketing strategy, social media management, content promotion, and branding</li>
              <li>Commitment Required: Once a month</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roles;
