import React from "react";
import Doctor from "../Assets/story.jpeg";
import "../Styles/Story.css";

function About() {
    return (
        <div className="sotry-container" id="story">
            <div>
                <div className="story-section">
                    <div className="story-image-content">
                        <img src={Doctor} alt="Purpose" className="story-image1" />
                    </div>

                    <div className="story-text-content">
                        <h3 className="story-title">
                            <span>Our Story</span>
                        </h3>
                        <p className="story-description">
                            As I embarked on my educational journey, my curiosity led me to explore ways to enhance healthcare in my community. Obtaining my EMT license, I delved into the field by joining Upper Valley Ambulance. After dedicating several hundred hours to this valuable experience, I began to discern a pattern. <br />It became evident that a significant portion of our emergency calls involved isolating aging adults. While we were responding to medical emergencies, I observed that many individuals were also in need of community support. These adults, often living alone in rural towns, found themselves isolated from both neighbors and the broader community due to health constraints confining them to their homes. In many cases, the issue was exacerbated post-COVID-19.<br />I couldn’t ignore the compelling evidence from numerous studies linking a sense of community to improved health outcomes. A feeling of togetherness alone was credited to reducing risks of dementia, high blood pressure, heart disease, a weakened immune system, and more.<br />Recognizing the need for a solution, I began conversations with local senior centers and professors, exploring viable solutions. After a few weeks observing senior center programs, our initiative Meals & Moments was conceived. While delivering meals through the Meals on Wheels program, we would simultaneously work to encourage isolated individuals to cultivate connections across generations and across communities.
                        </p>
                    </div>

                </div>
                <p className="story-description2">
                    Our purpose expanded beyond this program, as we worked to tackle the issue at its root. We envisioned a world where everyone had the necessary tools for healthy aging, not only through community engagement but through knowledge of crucial preventative health measures. Thus, we initiated healthcare classes covering topics proven to be common issues faced by aging adults.<br />We embraced humility and empathy to form connections with senior centers, working to integrate ourselves within the community. Through grassroots connections, we plan to knit together aging adults into cohesive communities that stretch beyond not only geographic isolation but social and cultural isolation as well. Our goal is one of empowerment, such that every individual feels a sense of place and purpose in their community.<br />Our journey is just beginning. As we look ahead, our commitment to this cause propels us forward. We are determined to bring cohesion to communities across the nation, irrespective of whether they are rural towns and bustling cities. In 2024, we hope to expand our programs and integrate them into the communities with which we’ve begun working. Soon after, we aspire to expand to new senior centers, and, ultimately, work to build new chapters in new communities.<br />Thank you for believing in our purpose and our mission, for standing alongside us, and being the driving force behind our nonprofit’s success. Together, we can empower individuals, laying the foundation for stronger communities—one person at a time.<br /><br />With gratitude, Vaishnavi Katragadda, Founder

                </p>
            </div>
        </div>
    );
}

export default About;
