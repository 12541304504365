import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";

function Step(props) {
    return (
        <div className="mission-text-step">
            <p className="mission-text-sTitle">
                <span className="mission-sTitle">
                    <FontAwesomeIcon className="mission-fa-icon" icon={faCircleChevronDown} />{" "}
                    {props.title}
                </span>
            </p>
            <p className="about-text-description">{props.description}</p>
        </div>
    );
}

export default Step;
